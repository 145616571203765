import { EnvironmentsEnum } from '@multiversx/sdk-dapp/types';
import nftLendingAbi from 'assets/abi/epunks-nft-lending.abi.json';
import nftAmmAbi from 'assets/abi/nft-amm.abi.json';
import jewelLendAbi from 'assets/abi/jewel-lend.abi.json';
import jewelFarmAbi from 'assets/abi/jewel-ashswap-farm.abi.json';
import ashsawpFarmAbi from 'assets/abi/farm.abi.json';
import jewelStakeAbi from 'assets/abi/jewel-stake.abi.json';
import jewelHtmStakeAbi from 'assets/abi/jewel-htm-stake.abi.json';
import jewelOnedexFarmAbi from 'assets/abi/jewel-onedex-farm.abi.json';
import jewelLiquidStakeAbi from 'assets/abi/jewel-egld-stake.abi.json';
import jewelGaugeAbi from 'assets/abi/gauge-sc.abi.json';
import jewelHatomFarmAbi from 'assets/abi/jewel-hatom-farm.abi.json';
import jewelStableAbi from 'assets/abi/jewel-stablecoin.abi.json';
import jewelFlashMintAbi from 'assets/abi/jewel-flashmint-sc.abi.json';
import jewelBorrowAbi from 'assets/abi/jewel-borrow.abi.json';
import jewelExchangeFarmAbi from 'assets/abi/jewel-xexchange-farm.abi.json';
import jewelSwapRouterAbi from 'assets/abi/jewel-swap-router.abi.json';
import jewelFlexiloanAbi from 'assets/abi/jewel-flexi-loan.abi.json';
import jewelGlobalPoolAbi from 'assets/abi/jewel-global-pool.abi.json';
import jewelOracleAbi from 'assets/abi/jewel-oracle.abi.json';
import jewelFacilitatorAbi from 'assets/abi/jewel-facilitator.abi.json';
import jewelBtcEthStakeAbi from 'assets/abi/jewel-btc-eth-stake.abi.json';
import hatomRewardsBoosterAbi from 'assets/abi/rewards-booster.abi.json';

import deadrare_logo from 'assets/img/marketplaces/deadrare_logo.webp';
import frameit_logo from 'assets/img/marketplaces/frameit_logo.svg';
import isengard_logo from 'assets/img/marketplaces/isengard_logo.png';
import xoxno_logo from 'assets/img/marketplaces/xoxno_logo.webp';

// Generate your own WalletConnect 2 ProjectId here: https://cloud.walletconnect.com/app
// export const walletConnectV2ProjectId = '9291145b1abc47b6a444559a36f7b7b4';
export const walletConnectV2ProjectId = 'a5886fd441f934cdc78aefff0aaefcf2';
export const apiTimeout = 10000;
export const transactionSize = 15;

export const SOLANA_DAPP_LINK = 'https://sol.jewelswap.io/';

export const RADIX_DAPP_LINK = 'https://xrd.jewelswap.io/';

export const SUI_DAPP_LINK = 'https://sui.jewelswap.io/';

export const dAppName = 'Epunks NFT Lending Liquidity Protocol';

export const isDev = process.env.REACT_APP_MVX_NETWORK === "devnet";

export const Environment = isDev ? EnvironmentsEnum.devnet : EnvironmentsEnum.mainnet;
export const metamaskSnapWalletAddress = 'https://snap-wallet.multiversx.com';

export const TOOLS_API_URL = 'https://tools.multiversx.com';
/**
 * Calls to these domains will use `nativeAuth` Baerer token
 */
export const sampleAuthenticatedDomains = [TOOLS_API_URL];


///////////////////////////////////////////////////////////////////////
export const NETWORK_MULTIVERSX_API = isDev ? 'https://devnet-api.multiversx.com' : 'https://api.multiversx.com';
export const NETWORK_PROXY_MULTIVERSX_API = isDev ? 'https://devnet-gateway.multiversx.com' : 'https://gateway.multiversx.com';
// export const NETWORK_MULTIVERSX_API = isDev ? 'https://testnet-api.multiversx.com' : 'https://api.multiversx.com';
// export const NETWORK_PROXY_MULTIVERSX_API = isDev ? 'https://testnet-gateway.multiversx.com' : 'https://gateway.multiversx.com';
export const NETWORK_JEWEL_API = 'https://proxy.jewelswap.io';
export const API_TIMEOUT = 10000;
// export const JEWEL_BACKEND_API = isDev ? 'http://localhost:3033/v1' : 'http://localhost:3033/v1';
// export const JEWEL_APP_URL = isDev ? 'http://localhost:3000/dashboard' : 'http://localhost:3000/dashboard';
export const JEWEL_BACKEND_API = isDev ? 'http://localhost:3033/v1' : 'https://shark-app-nsbrk.ondigitalocean.app/v1';
export const JEWEL_APP_URL = isDev ? 'http://localhost:3000/dashboard' : 'https://app.jewelswap.io/dashboard';

///////////////////////////////////////////////////////////////////////
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const NFT_LENDING_SC_ADDRESS = isDev ? "erd1qqqqqqqqqqqqqpgqk0f3sw6ssqn6e0f39fyrh75k8fh68mds9ckqpw42d6" : "erd1qqqqqqqqqqqqqpgqhpauarfmx75nf4pwxh2fuy520ym03p8e8jcqt466up";
export const NFT_LENDING_SC_ABI_JSON = nftLendingAbi;
export const NFT_LENDING_SC_MIN_BALANCE = 50;

// NFT AMM
export const NFT_AMM_SC_ADDRESS = isDev ? "erd1qqqqqqqqqqqqqpgqt7fw6m60l0h6ssaml0cyxgvajvw56le45zvs3ppl2u" : "erd1qqqqqqqqqqqqqpgq0erzpydzvy5unmj7867vaapddpc870x44qvqe209k4";
export const NFT_AMM_SC_ABI_JSON = nftAmmAbi;

// Farm
export const JEWEL_LEND_V2_SC_ADDRESS = isDev ? 'erd1qqqqqqqqqqqqqpgqa3qa5vtlymn0c97dh4qdqkey73cu6sz05zvs59n57r' : 'erd1qqqqqqqqqqqqqpgq96n4gxvmw8nxgxud8nv8qmms5namspc5vmusg930sh';
export const JEWEL_LEND_V2_SC_ABI_JSON = jewelLendAbi;

export const JEWEL_ASHSWAP_SC_ADDRESS = isDev ? 'erd1qqqqqqqqqqqqqpgqage48a0nan2c0cevl6z8ss5fs06fr8r45zvswygmg8' : 'erd1qqqqqqqqqqqqqpgqlnxy2hmvs8qxr6ezq2wmggn7ev62cjp6vmusvdral4';
export const JEWEL_ASHSWAP_SC_ABI_JSON = jewelFarmAbi;

export const ASHSWAP_FARM_SC_ABI_JSON = ashsawpFarmAbi;
export const JEWEL_LEND_MAX_BORROWING_CAP_PERCENT = 0.7; // 70%

export const ASHSWAP_VOTING_ESCROW_ADDRESS = isDev ? 'erd1qqqqqqqqqqqqqpgqynvujuhqhrn6qxf3cl49dmnpxt8mqqzc2gespkqv78' : 'erd1qqqqqqqqqqqqqpgq58elfqng8edp0z83pywy3825vzhawfqp4fvsaldek8';
export const VEASH_FEE_ADDRESS = isDev ? 'erd1u2yphggzv5k6qeuluuu3flc3rlar33ddw70jp34evferxt6tdhmq3w0a8l' : 'erd1u2yphggzv5k6qeuluuu3flc3rlar33ddw70jp34evferxt6tdhmq3w0a8l';

export const ASHSWAP_API_GRAPHQL_URL = isDev ? 'https://api-v2-devnet2.ashswap.io/graphql' : 'https://api-v2.ashswap.io/graphql';
export const ASHSWAP_API_URL = isDev ? 'https://api-devnet2.ashswap.io' : 'https://api.ashswap.io';
export const AUTO_COMPOUND_CRON_JOB_TIME = 600; // 10 min
export const JEWEL_ASHSWAP_SC_DEPLOYED_TIMESTAMP = 1682614896;
export const JEWEL_SEGLD_LEND_SC_DEPLOYED_TIMESTAMP = 1689523887;
export const JEWEL_JWLEGLD_LEND_SC_DEPLOYED_TIMESTAMP = 1691032092;
export const JEWEL_JWLASH_LEND_SC_DEPLOYED_TIMESTAMP = 1691685600;
export const JEWEL_WSDAI_LEND_SC_DEPLOYED_TIMESTAMP = 1696405822;

// ASH Stake
export const JEWEL_STAKE_SC_ADDRESS = isDev ? 'erd1qqqqqqqqqqqqqpgqvnhkkjraf26tef0qmdujpe3rsqn05zwa5zvsg3vy9j' : 'erd1qqqqqqqqqqqqqpgqhw2s04kx5crn2yvx5p253aa8fmganjjqdfysjvnluz';
export const JEWEL_STAKE_SC_ABI_JSON = jewelStakeAbi;

// Hatom Stake
export const JEWEL_HTM_STAKE_SC_ADDRESS = isDev ? 'erd1qqqqqqqqqqqqqpgqmse8c36pszxftesllmdufmsdewxk8wz05zvszddf2u' : 'erd1qqqqqqqqqqqqqpgqrhulnk8ughrghu9vyuy5hatd2nmaewnfvmuswq0wsv';
export const JEWEL_HTM_STAKE_SC_ABI_JSON = jewelHtmStakeAbi;

// Mex Stake
export const JEWEL_MEX_STAKE_SC_ADDRESS = isDev ? 'erd1qyu5wthldzr8wx5c9ucg8kjagg0jfs53s8nr3zpz3hypefsdd8ssycr6th' : 'erd1qqqqqqqqqqqqqpgqa4ws73d57lvsgvm7cn63rx8rt3ks44pqvmus39n5z6';

// Utk Stake
export const JEWEL_UTK_STAKE_SC_ADDRESS = isDev ? 'erd1qyu5wthldzr8wx5c9ucg8kjagg0jfs53s8nr3zpz3hypefsdd8ssycr6th' : 'erd1qqqqqqqqqqqqqpgqmnr9vuvmel3gaa75t4wkwddtlvj3948kvmusmhvp7g';

// itheum stake
export const JEWEL_ITHEUM_STAKE_SC_ADDRESS = isDev ? 'erd1qqqqqqqqqqqqqpgqhmxwuf9qw3fthy43gw9qz9zqd9elrge4vmusxua78k' : 'erd1qqqqqqqqqqqqqpgqhmxwuf9qw3fthy43gw9qz9zqd9elrge4vmusxua78k';

// tada stake
export const JEWEL_TADA_STAKE_SC_ADDRESS = isDev ? 'erd1qqqqqqqqqqqqqpgqwjpvqpv36ujpaqmj7kh8flvwaqy0mjqhvmusma3y0w' : 'erd1qqqqqqqqqqqqqpgqwjpvqpv36ujpaqmj7kh8flvwaqy0mjqhvmusma3y0w';
// OneDex Farm
export const JEWEL_ONEDEX_FARM_SC_ADDRESS = isDev ? 'erd1qqqqqqqqqqqqqpgq7auuhhzkpcdjzw6wzcpnjeq7mhn72zawdfysj8lde0' : 'erd1qqqqqqqqqqqqqpgqm7exdla3rzshywy99pvlxzkr45wt9kjsdfys7qqpn0';
export const JEWEL_ONEDEX_FARM_ABI_JSON = jewelOnedexFarmAbi;

// Liquid stake
export const JEWEL_LIQUID_STAKE_SC_ADDRESS = isDev ? 'erd1qqqqqqqqqqqqqpgqrg3w3fu6cnslt854tmxy5zg5hs0a4mxw5zvsm73xh7' : 'erd1qqqqqqqqqqqqqpgqx6833qjac6uqztgsa8jhlztexucke0hrdfys6wd7qt';
export const JEWEL_LIQUID_STAKE_SC_ABI_JSON = jewelLiquidStakeAbi;
export const JEWEL_LIQUID_STAKE_SC_DEPLOYED_TIMESTAMP = isDev ? 1687353132 : 1687426836;

// Gauge
export const JEWEL_GAUGE_SC_ADDRESS = isDev ? 'erd1qqqqqqqqqqqqqpgq7tyn06jh5eg8phmap5stsd4dp32fcpu55zvsjptnu8' : 'erd1qqqqqqqqqqqqqpgqn5yc7jlt82y84g0wf87ucjhq485x2wzldfysjm8k4x';
export const JEWEL_GAUGE_SC_ABI_JSON = jewelGaugeAbi;

// Jewelswap Router
export const JEWEL_SWAP_ROUTER_SC_ADDRESS = isDev ? 'erd1qqqqqqqqqqqqqpgqpyvwucddlr4ukay6nlx9w4glcr0dy5ttd8ssgjnx62' : 'erd1qqqqqqqqqqqqqpgqjd42wh3zsfkdnrz6r56mscdy2v7m4kjedfyssue65y';
export const JEWEL_SWAP_ROUTER_SC_ABI_JSON = jewelSwapRouterAbi;

// Hatom Farm
export const JEWEL_HATOM_FARM_SC_ADDRESS = isDev ? 'erd1qqqqqqqqqqqqqpgqdshcjrxcj337mw6m3ujgrtrvxpv57gad5zvsymj6s6' : 'erd1qqqqqqqqqqqqqpgqxdgq3ljejerqew4e22u0u5pr4vejx6p3dfysfwxqww';
export const JEWEL_HATOM_FARM_SC_ABI_JSON = jewelHatomFarmAbi;
export const HATOM_SEGLD_MM_ADDRESS = isDev ? 'erd1qqqqqqqqqqqqqpgqrrg87jj4yt7fhakg4uxy7204z9ghagxsv5ysszn8ak' : '';
export const HATOM_API_GRAPHQL_URL = isDev ? 'https://devnet-develop-api.hatom.com/graphql' : 'https://mainnet-api.hatom.com/graphql';
export const HATOM_INITIAL_EXCHANGE_RATE = "1000000000000000000";
export const HATOM_REWARDS_BOOSTER_SC_ADDRESS = isDev ? 'erd1qqqqqqqqqqqqqpgqlzpkdphhcmq0z0tjw523k784n78j7u56v5ysz8qx69' : 'erd1qqqqqqqqqqqqqpgqw4dsh8j9xafw45uwr2f6a48ajvcqey8s78sstvn7xd';
export const HATOM_REWARDS_BOOSTER_SC_ABI_JSON = hatomRewardsBoosterAbi;

// JWLUSD
export const JEWEL_STABLE_SC_ADDRESS = isDev ? 'erd1qqqqqqqqqqqqqpgqxr2g6c7hfj692euv2l9te0kyhaqjjdmn5zvsg8zepw' : 'erd1qqqqqqqqqqqqqpgq77f8cz5c26r7j4n08lndc8mrqc6x397edfysshzfg6';
export const JEWEL_STABLE_SC_ABI_JSON = jewelStableAbi;
export const JEWEL_STABLE_SC_DEPLOYED_TIMESTAMP = isDev ? 1692387444 : 1693327002;
export const WDAI_TOKEN_ID = 'WDAI-9eeb54';

// Jewel Flash Mint
export const JEWEL_FLASH_MINT_SC_ADDRESS = isDev ? 'erd1qqqqqqqqqqqqqpgq0shkynk0pf77648kjlzjhphx8ej5urxq5zvsk55kej' : 'erd1qqqqqqqqqqqqqpgq2mj3hkm9qalzr92yxr6xdkey7yrsa8qcuslslkhnx6';
export const JEWEL_FLASH_MINT_SC_ABI_JSON = jewelFlashMintAbi;

// Jewel Borrow
export const JEWEL_BORROW_SC_ADDRESS = isDev ? 'erd1qqqqqqqqqqqqqpgqf834wrlc2h0vjayu5r75cejrqh4rf75d5zvssuunct' : 'erd1qqqqqqqqqqqqqpgqmq8x2w5r86zr6n62qhmlep32wvp3wx7qvmusrtsnfm';
export const JEWEL_BORROW_SC_ABI_JSON = jewelBorrowAbi;

// Jewel xExchange Farm
export const JEWEL_EXCHANGE_SC_ADDRESS = isDev ? 'erd1qqqqqqqqqqqqqpgqwhsfz76jtfysfmuhvtx40txxlmw44nfp5zvsj2kjfc' : 'erd1qqqqqqqqqqqqqpgq9slqavjm7pglxgzuskwlvnq53gnk02vndfysq95mpq';
export const JEWEL_EXCHANGE_SC_ABI_JSON = jewelExchangeFarmAbi;
export const JEWEL_EXCHANGE_SC_DEPLOYED_TIMESTAMP = isDev ? 0 : 0;
export const LOCKED_TOKEN_WRAPPER_ADDRESS = isDev ? 'erd1qqqqqqqqqqqqqpgqasr2asq07e6ur274eecx3vd0pnej2vxs0n4sqqyp52' : 'erd1qqqqqqqqqqqqqpgqu64gygjs5ted4rupaewaszyhaxl9lv7m2jpsw47nzr';
export const ENERGY_FACTORY_ADDRESS = isDev ? 'erd1qqqqqqqqqqqqqpgqs7qnuvj2t0m40xmgrrh88wc7qdx286c60n4spvrzzk' : 'erd1qqqqqqqqqqqqqpgq0tajepcazernwt74820t8ef7t28vjfgukp2sw239f3';

// Jewel Flexiloan
export const JEWEL_FLEXILOAN_SC_ADDRESS = isDev ? 'erd1qqqqqqqqqqqqqpgqteustsled4937xsfdx3wwyethrvguz4g5zvs42wzw3' : '';
export const JEWEL_FLEXILOAN_SC_ABI_JSON = jewelFlexiloanAbi;

// Jewel Global Borrow Pool
export const JEWEL_GLOBAL_POOL_SC_ADDRESS = isDev ? 'erd1qqqqqqqqqqqqqpgqzfxwx3cz4ck6eysv7ljj40xj3ezysra55zvshv2p2x' : 'erd1qqqqqqqqqqqqqpgql32rvz84kfxnm4cvardr500pjqrt3pezdfys7ly0zx';
export const JEWEL_GLOBAL_POOL_SC_ABI_JSON = jewelGlobalPoolAbi;

export const JEWEL_ORACLE_SC_ADDRESS = isDev ? 'erd1qqqqqqqqqqqqqpgqw50eq3589kv89lknu52anwk7xvc4ykqr5zvs8n867l' : 'erd1qqqqqqqqqqqqqpgqnagsft6ld5uthszx26q5kknejgv88mmfdfys0g8ydl';
export const JEWEL_ORACLE_SC_ABI_JSON = jewelOracleAbi;

// Jewel facilitator
export const JEWEL_FACILITATOR_SC_ADDRESS = isDev ? 'erd1qqqqqqqqqqqqqpgqn82cj9nns4mh4kd368xtv5x38qheu3p25zvsgqxgyg' : 'erd1qqqqqqqqqqqqqpgq8luflvq3kae2lnx5uk7flhftc00wjrfndfyst2wj5u';
export const JEWEL_FACILITATOR_SC_ABI_JSON = jewelFacilitatorAbi;

// Jewel BTC, ETH Stake
export const JEWEL_BTC_ETH_STAKE_SC_ADDRESS = isDev ? 'erd1qqqqqqqqqqqqqpgqfftthehp5zkqnyvwenznjet0y4wluuva5zvs3a8u2d' : 'erd1qqqqqqqqqqqqqpgqyala2euzwlexef2wj7nj4aaa82v6mxq2vmus2k3clr';
export const JEWEL_BTC_ETH_STAKE_ABI_JSON = jewelBtcEthStakeAbi;
export const JEWEL_BTC_ETH_SC_DEPLOYED_TIMESTAMP = isDev ? 1692387444 : 1709681250;

//
export const JEWEL_LIQUID_RESTAKING_ADDRESS = isDev ? 'erd1qqqqqqqqqqqqqpgqxdrka7y9jzz9g3ymj48ddavy6wrgnf9q5zvswn9v4r' : 'erd1qqqqqqqqqqqqqpgq9sd3q0amkhgw0mnt33g23nj5q4mzmlwudfysmh9ej8';

export const STAKING_PROVIDER_ADDRESS = isDev ? 'erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqx0llllsdx93z0' : 'erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqrhlllls062tu4';
export const MAIAR_TOKENS_API = 'https://maiartokens.com/one-dex/token-value/latest?timestamp=';
export const XEXCHANGE_TOKENS_API = 'https://maiartokens.com/token-value/latest/all';
export const NFT_LEND_API = 'https://api-nfts.jewelswap.io';

export const WEGLD_TOKEN_ID = isDev ? 'WEGLD-a28c59' : 'WEGLD-bd4d79';
export const SEGLD_TOKEN_ID = isDev ? 'SEGLD-f94c36' : 'SEGLD-3ad2d0';
export const USDT_TOKEN_ID = isDev ? 'USDT-58d5d0' : 'USDT-f8c08c';
export const USDC_TOKEN_ID = isDev ? 'USDC-350c4e' : 'USDC-c76f1f';
export const ASH_TOKEN_ID = isDev ? 'ASH-e3d1b7' : 'ASH-a642d1';
export const XMEX_TOKEN_ID = isDev ? 'XMEX-38c7fb' : 'XMEX-fda355';
export const WXMEX_TOKEN_ID = isDev ? 'WXMEX-7fd55c' : 'WXMEX-794dbd';
export const BUSD_TOKEN_ID = isDev ? 'BUSD-866948' : 'BUSD-40b57e';
export const WBTC_TOKEN_ID = isDev ? 'WBTC-05fd5b' : 'WBTC-5349b3';
export const WETH_TOKEN_ID = isDev ? 'WETH-bbe4ab' : 'WETH-b4ca29';
export const APUSDC_TOKEN_ID = isDev ? '' : 'APUSDC-1ac537';
export const HTM_TOKEN_ID = isDev ? 'HTM-23a1da' : 'HTM-f51d55';
export const MEX_TOKEN_ID = isDev ? 'MEX-a659d0' : 'MEX-455c57';
export const WTAO_TOKEN_ID = isDev ? 'WTAO-a0cc6b' : 'WTAO-4f5363';
export const RSEGLD_TOKEN_ID = isDev ? 'RSEGLD-d54434' : 'RSEGLD-881ddd';
export const SRSEGLD_TOKEN_ID = isDev ? 'SRSEGLD-d54434' : 'SRSEGLD-881ddd';

export const MAX_LEVERAGE = 3;
export const STABLE_MAX_LEVERAGE = 4.5;
export const SLIPPAGE = 1;

export const MAX_BULK_LOAN_SIZE = 30;

export const LIQUID_STAKE_EPOCH_SECONDS = isDev ? 7200 : 86400;

// export const JEWEL_FARM_POOLS: JewelFarmPool[] = [
//   {
//     sc_address: JEWEL_ASHSWAP_SC_ADDRESS,
//     token_ids: [
//       {
//         token_id: USDT_TOKEN_ID,
//         token_decimal: 6,
//         token_image: usdt_logo,
//       },
//       {
//         token_id: ASH_TOKEN_ID,
//         token_decimal: 18,
//         token_image: ash_logo,
//       },
//     ],
//   },
// ];

// devnet
export const contracts = {
  'Wrap0': {
    address: isDev ? 'erd1qqqqqqqqqqqqqpgqqkwzsxkjc83vlfex9dmznwm7tjvxlqqkpauqx0n782' : 'erd1qqqqqqqqqqqqqpgqvc7gdl0p4s97guh498wgz75k8sav6sjfjlwqh679jy'
  },
  'Wrap1': {
    address: isDev ? 'erd1qqqqqqqqqqqqqpgqpv09kfzry5y4sj05udcngesat07umyj70n4sa2c0rp' : 'erd1qqqqqqqqqqqqqpgqhe8t5jewej70zupmh44jurgn29psua5l2jps3ntjj3'
  },
  'Wrap2': {
    address: isDev ? 'erd1qqqqqqqqqqqqqpgqvn9ew0wwn7a3pk053ezex98497hd4exqdg0q8v2e0c' : 'erd1qqqqqqqqqqqqqpgqmuk0q2saj0mgutxm4teywre6dl8wqf58xamqdrukln'
  }
};

// testnet
// export const contracts = {
//   'Wrap0': {
//     address: isDev ? 'erd1qqqqqqqqqqqqqpgq7gp69s5k8xyv5z668pqhn2g8yatzr663295qeww2dg' : 'erd1qqqqqqqqqqqqqpgqvc7gdl0p4s97guh498wgz75k8sav6sjfjlwqh679jy'
//   },
//   'Wrap1': {
//     address: isDev ? 'erd1qqqqqqqqqqqqqpgqfy4z88duqdusaksn399f4dny527m6xe07h0sll0m2t' : 'erd1qqqqqqqqqqqqqpgqhe8t5jewej70zupmh44jurgn29psua5l2jps3ntjj3'
//   },
//   'Wrap2': {
//     address: isDev ? 'erd1qqqqqqqqqqqqqpgqyjudtgtm76ch59lktf454uvsz8qtntlplyrqw30vfm' : 'erd1qqqqqqqqqqqqqpgqmuk0q2saj0mgutxm4teywre6dl8wqf58xamqdrukln'
//   }
// };

export const ADMIN_ADDRESS = isDev ? [
  'erd126rkz9fle5uc9l6z8hqzsfkvy50zy4a4me2748drg0re0pqx6gdq4vzz9z',
  'erd1sznpu0vk26uesk8aq03nx5t8uxfw3tngajmmy2jsr58xk3j39ckqj7qfyc',
  'erd1ygdttzrulwfspme2s4qrx5y2qyfqalju0k2vcyy8z3979whlj9qssl5uay',
  'erd149axj8feledcw7zck5f3ecwrncgd0gemcr9q69yxqlk0zvnl5zvs065jqu',
] : [
  'erd126rkz9fle5uc9l6z8hqzsfkvy50zy4a4me2748drg0re0pqx6gdq4vzz9z',
  'erd1sznpu0vk26uesk8aq03nx5t8uxfw3tngajmmy2jsr58xk3j39ckqj7qfyc',
  'erd1ygdttzrulwfspme2s4qrx5y2qyfqalju0k2vcyy8z3979whlj9qssl5uay',
  'erd149axj8feledcw7zck5f3ecwrncgd0gemcr9q69yxqlk0zvnl5zvs065jqu',
  "erd17dxf0yjt4dkdert632g64xkd3gp47rkmh5tueysy493gsqggr4wstprfjz"
];

// Gas Limit
export const MAX_GAS_LIMIT = 600_000_000;
export const LEND_GAS_LIMIT = 130_000_000;
export const UNWRAP_GAS_LIMIT = 80_000_000;
export const BORROW_GAS_LIMIT = 41_000_000;
export const CLAIM_REWARDS_GAS_LIMIT = 80_000_000;
export const DOWNPAYMENT_GAS_LIMIT = 80_000_000;

export const ENTER_FARM_GAS_LIMIT = 500_000_000;
export const GOV_ACTION_GAS_LIMIT = 30_000_000;
export const JWLASH_CONVERT_GAS_LIMIT = 600_000_000;
export const JWLASH_CONVERT_LOCK_GAS_LIMIT = 600_000_000;
export const JWLASH_STAKE_GAS_LIMIT = 20_000_000;
export const JWLASH_UNSTAKE_GAS_LIMIT = 30_000_000;
export const JWLASH_CLAIM_GAS_LIMIT = 220_000_000;
export const JEWEL_ONEDEX_FARM_GAS_LIMIT = 200_000_000;
export const JEWEL_LIQUID_STAKE_GAS_LIMIT = 250_000_000;
export const JEWEL_LIQUID_UNSTAKE_GAS_LIMIT = 30_000_000;
export const JEWEL_LIQUID_DEPOSIT_GAS_LIMIT = 30_000_000;
export const JWLASH_GAUGE_GAS_LIMIT = 10_000_000;

// Dashboard
export const LATEST_LOAN_SHOW_SIZE = 5;
export const TOP_TRADE_COLLECTIONS_COUNT = 12;

///////////////////////////////////////////////////////////////////////
export enum NftMarketplaceEnum {
  XOXNO = 'XOXNO',
  DEADRARE = 'DEADRARE',
  FRAMEIT = 'FRAMEIT',
  ISENGARD = 'ISENGARD',
}

export const nftMarketplaceMetadata = {
  'XOXNO': {
    logo: xoxno_logo,
  },
  'DEADRARE': {
    logo: deadrare_logo,
  },
  'FRAMEIT': {
    logo: frameit_logo,
  },
  'ISENGARD': {
    logo: isengard_logo,
  },
};

export const buyDownPageSize = 8;

export const JWLEGLD_TOKEN_ID = isDev ? 'JWLEGLD-e4b8d3' : 'JWLEGLD-023462';
export const JWLUSD_TOKEN_ID = isDev ? 'JWLUSD-3d1fab' : 'JWLUSD-62939e';

export const ISOLATED_BORROW_POOL_LIQUIDATION_PERCENT = 80_0000;

export const JEWEL_BORROW_LIQUIDATION_TOKENS = [ASH_TOKEN_ID, HTM_TOKEN_ID, MEX_TOKEN_ID];
