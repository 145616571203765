import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useGetAccount, useGetIsLoggedIn, useGetNetworkConfig, useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import { FarmPool } from 'components/FarmPool';
import egld_white_logo from 'assets/img/EGLD_white.svg';
import { queryViewJewelFarmBaseContextData, queryViewJewelFarmBaseContext, queryViewJewelLendContext, queryOnedexFarmBaseContext, queryOnedexViewFarms, queryViewJewelFarms, queryHatomFarmCommonContext, queryHatomViewFarms, queryJewelFlashMintCommonContext, queryJewelFlashMintViewFarms, queryLiquidStakStatsContext, queryJewelExchangeFarmBaseContext, queryJewelExchangeFarms, queryJewelFlashMintViewLpFarms, queryHatomBoosterAccruedRewards, queryHatomBoosterAccountStake } from 'z/elrond';
import { JewelFarmBaseContextData, JewelFarmBaseContext, JewelFarmContextData, JewelFarmContext, JewelLendPool, JewelOnedexFarmBaseContext, JewelOnedexFarmContext, AshswapPool, JewelHatomFarmCommonContext, JewelHatomFarmContext, JewelHatomMoneyMarket, JewelFlashMintCommonContext, JewelFlashMintFarmContext, AshswapFarmCommonSetting, AshswapFarm, JewelExchangeFarmBaseContext, JewelExchangeFarmContext, JewelLpFarmContext, JewelswapFarm } from 'z/types';
import { Spinner } from 'components/Spinner';
import { OneDexFarmPool } from 'components/FarmPool/onedexFarmPool';
import { calcHatomLiquidStakingAPY, convertBigNumberValueToLocalString, convertWeiToEsdt, DAY_IN_SECONDS, FARM_MAX_PERCENT, getLiquidStakeApy, getTokenDecimal, getTokenImage, getTokenTicker, hatomFormatRewards, SECOND_TO_MILLISECONDS } from 'z/utils';
import { APUSDC_TOKEN_ID, BUSD_TOKEN_ID, HTM_TOKEN_ID, isDev, MAIAR_TOKENS_API } from 'config';
import axios from 'axios';
import { getAshswapCommonSetting, getAshswapFarms, getAshswapPools, getExchangePairs, getHatomControllerAddress, getHatomLiquidStakingApyInfo, getHatomMoneyMarket, getHatomRewardsBatch, getHatomTokenPrices, getHTokenExchangeRate, getJewelHatomAprs, getJewelswapFarms, getOnedexPairs, getTokenPriceFromAshswap, getUserTokenBalance, getXexchangeTokenPrices } from 'z/api';
import { ExchangeFarmPool } from 'components/FarmPool/exchangeFarmPool';
import { PROTOCOL_ESDT_TOKENS } from 'data';
import { HatomFarmPool } from 'components/FarmPool/HatomFarmPool';
import DefiUtils from 'defi-utils';
import { JewelFarmPool } from 'components/FarmPool/JewelFarmPool';
import { AshswapFarmPool } from 'components/FarmPool/AshswapFarmPool';
import { JewelLpFarmPool } from 'components/FarmPool/JewelLpFarmPool';
import { JewelHistory } from 'z/types/history';
import BigNumber from 'bignumber.js';
import { XexchangeFarmInfo, XexchangePairInfo, getXexchangeFarmsInfo, getXexchangePairInfo } from 'z/elrond/xexchange-api';

export const Farm = () => {

    const {
        network: { apiAddress },
    } = useGetNetworkConfig();
    const { address, balance } = useGetAccount();
    // const address = 'erd1ju8vc2pyec85w4tzl3ek865n4tm650kluhe3w9l93vlrhdukyc4sfuw6qs';
    const isLoggedIn = useGetIsLoggedIn();
    const { hasPendingTransactions } = useGetPendingTransactions();

    const { dex } = useParams();
    const [dexType, setDexType] = useState<string>('');
    useEffect(() => {
        if (dex) {
            setDexType(dex);
        } else {
            setDexType('ashswap');
        }
    }, [dex]);

    const navigate = useNavigate();
    const handleRouter = (dex: string) => {
        navigate(`/farm/${dex}`);
    };

    // ashswap farm for mainnet
    const [ashswapCommonSetting, setAshswapCommonSetting] = useState<AshswapFarmCommonSetting | undefined>();
    const [ashswapFarms, setAshswapFarms] = useState<AshswapFarm[]>([]);
    const [ashswapTokens, setAshswapTokens] = useState<any>();

    useEffect(() => {
        if (isDev) return;

        (async () => {
            const commonSetting = await getAshswapCommonSetting();
            if (commonSetting) {
                // console.log('ashswap-commonSetting: ', commonSetting);
                setAshswapCommonSetting(commonSetting);
            }
            const _tokens = await getTokenPriceFromAshswap();
            // console.log('ashswap-tokens: ', _tokens);
            setAshswapTokens(_tokens);
        })();
    }, []);
    useEffect(() => {
        if (isDev) return;

        (async () => {
            const farms = await getAshswapFarms();
            // console.log('ashswap-farms: ', farms);
            setAshswapFarms(farms);
        })();
    }, []);

    // ashswap farm for devnet
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [ashswapPools, setAshswapPools] = useState<AshswapPool[]>([]);
    const [commonSettings, setCommonSettings] = useState<JewelFarmBaseContext | undefined>();
    const [jewelAshswapFarms, setJewelAshswapFarms] = useState<JewelFarmContext[]>([]);
    useEffect(() => {
        if (hasPendingTransactions || !isDev) return;

        (async () => {
            setIsLoading(true);

            const _ashswapPools = await getAshswapPools();
            // console.log('_ashswapPools: ', _ashswapPools);
            setAshswapPools(_ashswapPools);

            const _commonSettings = await queryViewJewelFarmBaseContext();
            if (_commonSettings) {
                // console.log('_commonSettings', _commonSettings);
                setCommonSettings(_commonSettings);
            }

            const _farms = await queryViewJewelFarms(apiAddress);
            setJewelAshswapFarms(_farms);

            setIsLoading(false);
        })();
    }, [hasPendingTransactions]);

    const [lendPools, setLendPools] = useState<JewelLendPool[]>([]);
    useEffect(() => {
        if (hasPendingTransactions) return;

        (async () => {
            const _lendCommonSettings = await queryViewJewelLendContext(apiAddress);
            // console.log('_lendCommonSettings', _lendCommonSettings);
            if (_lendCommonSettings) {
                setLendPools(_lendCommonSettings.lendPool);
            }
        })();
    }, [hasPendingTransactions]);

    const [userTokens, setUserTokens] = useState<any>();
    useEffect(() => {
        if (hasPendingTransactions) return;

        (async () => {
            const userTokensBalance: any = [];
            if (isLoggedIn) {
                const balances = await getUserTokenBalance(apiAddress, address, PROTOCOL_ESDT_TOKENS);
                // console.log('balances ', balances);

                userTokensBalance['EGLD'] = {
                    balance: balance,
                };

                for (const item of balances) {
                    userTokensBalance[item.identifier] = {
                        balance: item.balance,
                    };
                }
            }

            for (const item of PROTOCOL_ESDT_TOKENS) {
                if (!userTokensBalance[item]) {
                    userTokensBalance[item] = {
                        balance: '0',
                    };
                }
            }

            // console.log('userTokensBalance: ', userTokensBalance);
            setUserTokens(userTokensBalance);
        })();
    }, [hasPendingTransactions, isLoggedIn]);

    const [isOnedexFarmLoading, setIsOnedexFarmLoading] = useState<boolean>(false);
    const [onedexFarmCommonSettings, setOnedexFarmCommonSettings] = useState<JewelOnedexFarmBaseContext | undefined>();
    const [onedexFarms, setOnedexFarms] = useState<JewelOnedexFarmContext[]>([]);
    const [onedexPairInfos, setOnedexPairInfos] = useState<any[]>([]);
    useEffect(() => {
        if (hasPendingTransactions) return;

        (async () => {
            setIsOnedexFarmLoading(true);
            const _onedexPairs = await getOnedexPairs();
            console.log('onedex-pairs: ', _onedexPairs);
            setOnedexPairInfos(_onedexPairs);

            const _exchangePairs = await getExchangePairs();
            console.log('xexchange-pairs: ', _exchangePairs);

            const _onedexFarmCommonSettings = await queryOnedexFarmBaseContext();
            console.log('_onedexFarmCommonSettings', _onedexFarmCommonSettings);
            if (_onedexFarmCommonSettings) {
                setOnedexFarmCommonSettings(_onedexFarmCommonSettings);
            }

            const _onedexFarms = await queryOnedexViewFarms(apiAddress, _onedexPairs, _exchangePairs);
            console.log('_onedexFarms', _onedexFarms);
            setOnedexFarms(_onedexFarms);

            setIsOnedexFarmLoading(false);
        })();
    }, [hasPendingTransactions]);

    // hatom
    // get apr from api
    const [jewelHatomAprs, setJewelHatomAprs] = useState<JewelHistory[]>([]);
    useEffect(() => {
        (async () => {
            const aprs = await getJewelHatomAprs();
            // console.log('jewel_hatom_aprs: ', aprs);
            setJewelHatomAprs(aprs);
        })();
    }, [hasPendingTransactions]);

    const [isHatomFarmLoading, setIsHatomFarmLoading] = useState<boolean>(false);
    const [hatomFarmCommonSettings, setHatomFarmCommonSettings] = useState<JewelHatomFarmCommonContext | undefined>();
    const [hatomFarms, setHatomFarms] = useState<JewelHatomFarmContext[]>([]);
    const [hatomMoneyMarkets, setHatomMoneyMarkets] = useState<JewelHatomMoneyMarket[]>([]);
    const [hatomFormattedRewards, setHatomFormattedRewards] = useState<any[]>([]);
    const [htmBoostStakeAmount, setHtmBoostStakeAmount] = useState<string>('0');
    const [htmPrice, setHtmPrice] = useState<number>(0);
    const [hatomBoosterPercentage, setHatomBoosterPercentage] = useState<string>('0');
    useEffect(() => {
        if (hasPendingTransactions) return;

        (async () => {
            setIsHatomFarmLoading(true);

            const _hatomFarmCommonSettings = await queryHatomFarmCommonContext();
            // console.log('_hatomFarmCommonSettings', _hatomFarmCommonSettings);
            if (_hatomFarmCommonSettings) {
                setHatomFarmCommonSettings(_hatomFarmCommonSettings);
            }

            const _hatomFarms = await queryHatomViewFarms();
            // console.log('_hatomFarms: ', _hatomFarms);
            setHatomFarms(_hatomFarms);

            // get token price
            const prices = await getXexchangeTokenPrices(apiAddress);
            let htmPrice = 0, wegldPrice = 0, utkPrice = 0, wethPrice = 0, wbtcPrice = 0, wtaoPrice = 0;
            for (const price of prices) {
                if (getTokenTicker(price.id) === 'HTM') htmPrice = price.price;
                if (getTokenTicker(price.id) === 'EGLD') wegldPrice = price.price;
                if (getTokenTicker(price.id) === 'UTK') utkPrice = price.price;
                if (getTokenTicker(price.id) === 'WETH') wethPrice = price.price;
                if (getTokenTicker(price.id) === 'WBTC') wbtcPrice = price.price;
                if (getTokenTicker(price.id) === 'WTAO') wtaoPrice = price.price;
            }

            let totalValue = new BigNumber('0');
            for (const farm of _hatomFarms) {
                if (getTokenTicker(farm.hatom_base_token_id) === 'SEGLD' || getTokenTicker(farm.hatom_base_token_id) === 'EGLD') {
                    totalValue = totalValue.plus(convertWeiToEsdt(farm.hatom_base_supply_amount, getTokenDecimal(farm.hatom_base_token_id)).multipliedBy(wegldPrice));
                } else if (getTokenTicker(farm.hatom_base_token_id) === 'UTK') {
                    totalValue = totalValue.plus(convertWeiToEsdt(farm.hatom_base_supply_amount, getTokenDecimal(farm.hatom_base_token_id)).multipliedBy(utkPrice));
                } else if (getTokenTicker(farm.hatom_base_token_id) === 'WETH') {
                    totalValue = totalValue.plus(convertWeiToEsdt(farm.hatom_base_supply_amount, getTokenDecimal(farm.hatom_base_token_id)).multipliedBy(wethPrice));
                } else if (getTokenTicker(farm.hatom_base_token_id) === 'WBTC') {
                    totalValue = totalValue.plus(convertWeiToEsdt(farm.hatom_base_supply_amount, getTokenDecimal(farm.hatom_base_token_id)).multipliedBy(wbtcPrice));
                } else if (getTokenTicker(farm.hatom_base_token_id) === 'WTAO') {
                    totalValue = totalValue.plus(convertWeiToEsdt(farm.hatom_base_supply_amount, getTokenDecimal(farm.hatom_base_token_id)).multipliedBy(wtaoPrice));
                } else {
                    totalValue = totalValue.plus(convertWeiToEsdt(farm.hatom_base_supply_amount, getTokenDecimal(farm.hatom_base_token_id)));
                }
            }

            const stakeAmount = await queryHatomBoosterAccountStake();

            const boostPercent = convertWeiToEsdt(stakeAmount, getTokenDecimal(HTM_TOKEN_ID)).multipliedBy(htmPrice).dividedBy(totalValue).multipliedBy(100);

            setHtmBoostStakeAmount(stakeAmount);
            setHtmPrice(htmPrice);
            setHatomBoosterPercentage(boostPercent.toFixed(2));
            setIsHatomFarmLoading(false);
        })();
    }, [hasPendingTransactions]);

    useEffect(() => {
        if (hasPendingTransactions) return;

        (async () => {
            // reward apy

            const [
                controllerAddress,
                rewardsBatch,
                tokenPricesMap,
                hTokenExchangeRateMap,
            ] = await Promise.all([
                getHatomControllerAddress(),
                getHatomRewardsBatch(),
                getHatomTokenPrices(),
                getHTokenExchangeRate(),
            ]);
            // console.log('controllerAddress: ', controllerAddress);
            // console.log('rewardsBatch: ', rewardsBatch);
            // console.log('tokenPricesMap: ', tokenPricesMap);
            // console.log('hTokenExchangeRateMap: ', hTokenExchangeRateMap);

            if (!controllerAddress) return;
            const tokenIds: string[] = [];
            tokenIds.push(...rewardsBatch.map(({ moneyMarket }: any) => moneyMarket.hToken.id));
            const accountTokens = await getUserTokenBalance(
                apiAddress,
                controllerAddress,
                tokenIds
            );
            const hTokensIdsMap: any = accountTokens.reduce(
                (prev, { name, balance }) => ({
                    ...prev,
                    [`${name.replace("Hatom", "H")}-`]: balance,
                }),
                {}
            );
            const wrappedIdsMap: any = accountTokens.reduce(
                (prev, { name, balance }) => ({
                    ...prev,
                    [`${name.replace("Wrapped", "")}-`]: balance,
                }),
                {}
            );

            const totalCollateralTokensMap = tokenIds.reduce((prev, currentIdenfitier) => {
                const balanceByIdentifier = accountTokens.find(
                    ({ identifier }) => identifier === currentIdenfitier
                )?.balance;
                const balanceByAsset = accountTokens.find(({ assets }) =>
                    assets?.pngUrl?.includes(currentIdenfitier)
                )?.balance;
                const balanceByHToken: any =
                    hTokensIdsMap[`${currentIdenfitier.split("-")?.[0] || ""}-`];

                const balanceByWrapped: any =
                    wrappedIdsMap[`${currentIdenfitier.split("-")?.[0] || ""}-`];

                return {
                    ...prev,
                    [currentIdenfitier]:
                        balanceByIdentifier ||
                        balanceByAsset ||
                        balanceByHToken ||
                        balanceByWrapped ||
                        "0",
                };
            }, {});
            // console.log('totalCollateralTokensMap: ', totalCollateralTokensMap);

            const formattedRewards = hatomFormatRewards({
                rewardsBatch,
                tokenPricesMap,
                hTokenExchangeRateMap,
                totalCollateralTokensMap,
            });
            // console.log('formattedRewards: ', formattedRewards);
            setHatomFormattedRewards(formattedRewards);
        })();
    }, [hasPendingTransactions]);

    useEffect(() => {
        if (hasPendingTransactions) return;

        (async () => {
            // supply, borrow apy
            const _hatomMoneyMarket = await getHatomMoneyMarket();
            if (_hatomMoneyMarket) {
                // console.log('_hatomMoneyMarket: ', _hatomMoneyMarket.data.queryMoneyMarket);
                setHatomMoneyMarkets(_hatomMoneyMarket.data.queryMoneyMarket);
            }
        })();
    }, [hasPendingTransactions]);

    // Jewel Flash Mint
    const [isJewelFlashMintLoading, setIsFlashMintLoading] = useState<boolean>(false);
    const [jewelFlashMintCommonSettings, setJewelFlashMintCommonSettings] = useState<JewelFlashMintCommonContext | undefined>();
    const [jewelFlashMintFarms, setJewelFlashMintFarms] = useState<JewelFlashMintFarmContext[]>([]);
    const [jewelLpFarms, setJewelLpFarms] = useState<JewelLpFarmContext[]>([]);
    const [jewelswapFarms, setJewelswapFarms] = useState<JewelswapFarm[]>([]);
    const [liquidStakeApy, setLiquidStakeApy] = useState<string>('0');
    useEffect(() => {
        if (hasPendingTransactions) return;

        (async () => {
            setIsFlashMintLoading(true);

            const _jewelFlashMintCommonSettings = await queryJewelFlashMintCommonContext();
            // console.log('_jewelFlashMintCommonSettings', _jewelFlashMintCommonSettings);
            if (_jewelFlashMintCommonSettings) {
                setJewelFlashMintCommonSettings(_jewelFlashMintCommonSettings);
            }

            const _jewelFlashMintFarms = await queryJewelFlashMintViewFarms();
            // console.log('_jewelFlashMintFarms: ', _jewelFlashMintFarms);
            setJewelFlashMintFarms(_jewelFlashMintFarms);

            let _jewelLpFarms = await queryJewelFlashMintViewLpFarms();
            
      _jewelLpFarms = _jewelLpFarms.filter(id=>id.jaf_farm_id!=10 );
      _jewelLpFarms = _jewelLpFarms.filter(id=>id.jaf_farm_id!=21 );
      _jewelLpFarms = _jewelLpFarms.filter(id=>id.jaf_farm_id!=24 );
      _jewelLpFarms = _jewelLpFarms.filter(id=>id.jaf_farm_id!=26 );
            //_jewelLpFarms = _jewelLpFarms.slice(0, _jewelLpFarms.length-1);
            // console.log('_jewelLpFarms: ', _jewelLpFarms);
            setJewelLpFarms(_jewelLpFarms);

            const _jewelSwapFarms: JewelswapFarm[] = [];
            const farms = await getJewelswapFarms();
            for (const jewelLpFarm of _jewelLpFarms) {
                const farm = farms.filter((item: JewelswapFarm) => item.farm_id === jewelLpFarm.lp_farm_id)[0];
                _jewelSwapFarms.push(farm);
            }
            setJewelswapFarms(_jewelSwapFarms);

            setIsFlashMintLoading(false);
        })();
    }, [hasPendingTransactions]);

    useEffect(() => {
        if (hasPendingTransactions) return;

        (async () => {
            const _liquidStakeStatsContext = await queryLiquidStakStatsContext();
            // console.log('_liquidStakeStatsContext', _liquidStakeStatsContext);

            if (_liquidStakeStatsContext) {

                const apy = getLiquidStakeApy(_liquidStakeStatsContext.jwlegld_pool_reserve, _liquidStakeStatsContext.sjwlegld_pool_reserve);
                setLiquidStakeApy(convertBigNumberValueToLocalString(convertWeiToEsdt(apy)));
            }
        })();
    }, [hasPendingTransactions]);

    // xExchange
    const [isExchangeFarmLoading, setIsExchangeFarmLoading] = useState<boolean>(false);
    const [exchangeFarmCommonSettings, setExchangeFarmCommonSettings] = useState<JewelExchangeFarmBaseContext | undefined>();
    const [exchangeFarms, setExchangeFarms] = useState<JewelExchangeFarmContext[]>([]);
    const [exchangeFarmsInfo, setExchangeFarmsInfo] = useState<XexchangeFarmInfo[]>([]);

    useEffect(() => {
        if (hasPendingTransactions) return;

        (async () => {
            setIsExchangeFarmLoading(true);

            const exchange_base_context = await queryJewelExchangeFarmBaseContext();
            // console.log('exchange_base_context', exchange_base_context);
            if (exchange_base_context) {
                setExchangeFarmCommonSettings(exchange_base_context);
            }

            let exchange_farms:JewelExchangeFarmContext[] = (await queryJewelExchangeFarms());
            exchange_farms = exchange_farms.filter((farm)=> farm.farm_address!="erd1qqqqqqqqqqqqqpgqapxdp9gjxtg60mjwhle3n6h88zch9e7kkp2s8aqhkg");
            // console.log('exchange_farms: ', exchange_farms);
            setExchangeFarms(exchange_farms);

            setIsExchangeFarmLoading(false);
        })();
    }, [hasPendingTransactions]);

    useEffect(() => {
      (async () => {
        let _farms = await queryJewelExchangeFarms();
        const _farmsInfo = await getXexchangeFarmsInfo();
        _farms = _farms.filter((farm)=> farm.farm_address!="erd1qqqqqqqqqqqqqpgqapxdp9gjxtg60mjwhle3n6h88zch9e7kkp2s8aqhkg");

        // filter listed farms
        const _filteredFarmsInfo = [];
        for (const farmInfo of _farmsInfo) {
          const _farm = _farms.find((farm) => farm.farm_address == farmInfo.address);
          if (_farm) {
            const _pairInfo = await getXexchangePairInfo(_farm.pair_address);
            if (_pairInfo) {
              farmInfo.totalApr = (farmInfo.maxBoostedApr + _pairInfo.feesAPR)*0.7;
            }
            _filteredFarmsInfo.push(farmInfo);
          }
        }
        setExchangeFarmsInfo(_filteredFarmsInfo);
      })();
    }, []);

    return (
        <div className='container' style={{ marginTop: '40px' }}>
            <div className='info-item'>
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='title'>
                        <span>Farms</span>
                    </div>
                    <div className='egld-logo' >
                        <img src={egld_white_logo} />
                    </div>
                </div>
                <div className='d-flex mt-3 mb-1 align-items-center w-100 overflow-auto' style={{ height: '40px' }}>
                    <div className='mx-2 h-100 d-flex align-items-center' style={{ fontSize: '17px' }}>DEX:</div>
                    <div className='d-flex ml-3'>
                        <div className={`mx-2 farm-dex-tab ${dexType === 'ashswap' && 'farm-dex-tab-active'}`} onClick={() => handleRouter('ashswap')}>Ashswap</div>
                        <div className={`mx-2 farm-dex-tab ${dexType === 'onedex' && 'farm-dex-tab-active'}`} onClick={() => handleRouter('onedex')}>OneDex</div>
                        <div className={`mx-2 farm-dex-tab ${dexType === 'hatom' && 'farm-dex-tab-active'}`} onClick={() => handleRouter('hatom')}>Hatom</div>
                        <div className={`mx-2 farm-dex-tab ${dexType === 'jewelswap' && 'farm-dex-tab-active'}`} onClick={() => handleRouter('jewelswap')}>JewelSwap</div>
                        <div className={`mx-2 farm-dex-tab ${dexType === 'xexchange' && 'farm-dex-tab-active'}`} onClick={() => handleRouter('xexchange')}>xExchange</div>
                    </div>
                </div>
                {
                    dexType === 'hatom' && (
                        <div className='row gy-2 gx-2 dashboard-egld-info' style={{ marginTop: '20px' }}>
                            <div className='col-sm-6'>
                                <div className='d-flex justify-content-between align-items-center dashboard-info'>
                                    <div className='d-flex align-items-center'>
                                        {
                                            !isMobile && (
                                                <img src={getTokenImage(HTM_TOKEN_ID)} alt={HTM_TOKEN_ID} width={25} height={25} style={{marginRight: '5px'}}></img>
                                            )
                                        }
                                        <span style={{ color: '#939da7', fontSize: '1.125rem' }}>HTM Booster</span>
                                    </div>
                                    <div className='d-flex justify-content-center dashboard-info-token'>
                                        <div className='text-end'>{`${convertBigNumberValueToLocalString(convertWeiToEsdt(htmBoostStakeAmount, getTokenDecimal(HTM_TOKEN_ID)))} ${getTokenTicker(HTM_TOKEN_ID)} (= ${convertBigNumberValueToLocalString(convertWeiToEsdt(htmBoostStakeAmount, getTokenDecimal(HTM_TOKEN_ID)).multipliedBy(htmPrice))} USDC)`}</div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className='d-flex justify-content-between dashboard-info'>
                                    <span>Percentage Boost</span>
                                    <div className='d-flex justify-content-center dashboard-info-token'>
                                        <div className='text-end'>{`${hatomBoosterPercentage}%${Number(hatomBoosterPercentage) > 20 ? ' (✅ USH Airdrop)' : ''}`}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                {!isMobile && dexType !== 'jewelswap' && (
                    <div className='row mt-5' style={{ justifyContent: "center" }}>
                        <div className='col-lg-3 col-md-3 col-sm-3 text-center'>
                            <div>Pool</div>
                        </div>
                        <div className='col-lg-3 col-md-3 col-sm-3 text-center'>
                            <div>TVL</div>
                        </div>
                        <div className='col-lg-2 col-md-2 col-sm-2 d-flex justify-content-center'>
                            <div className='d-flex'>
                                <div>{`Max APY${dexType === 'hatom' ? ' / APR(24H)' : ''}`}</div>
                                <Tooltip
                                    placement="top"
                                    title="Based on APY of the current underlying farming pools. It does not include fees from occasional rebalancing"
                                >
                                    <InfoCircleOutlined className='h6 mx-1' style={{ marginTop: '1px', cursor: 'pointer' }} />
                                </Tooltip>
                            </div>
                        </div>
                        <div className='col-lg-2 col-md-2 col-sm-2 text-center'>
                            <div>Leverage</div>
                        </div>
                        <div className='col-lg-2 col-md-2 col-sm-2'></div>
                    </div>
                )}

                {
                    dexType === 'ashswap' && isDev && (
                        isLoading ? (
                            <div className='mt-5'>
                                <Spinner />
                            </div>
                        ) : (
                            <div className='row mt-1' style={{ justifyContent: "center" }}>
                                {userTokens && commonSettings && lendPools.length > 0 && (
                                    <div className='col-12'>
                                        {jewelAshswapFarms.map((farmPool: JewelFarmContext, index: number) => (
                                            <FarmPool
                                                key={index}
                                                ashswapPools={ashswapPools}
                                                commonSettings={commonSettings}
                                                farm={farmPool}
                                                lendPools={lendPools}
                                                userTokens={userTokens}
                                                farmIndex={index}
                                                poolName='AshSwap'
                                            />
                                        ))}
                                    </div>
                                )}
                            </div>
                        )
                    )
                }

                {
                    dexType === 'ashswap' && !isDev && (
                        ashswapCommonSetting && lendPools.length > 0 && ashswapFarms.length > 0 ? (
                            <div className='row mt-1' style={{ justifyContent: "center" }}>
                                <div className='col-12'>
                                    {ashswapFarms.map((farm: AshswapFarm, index: number) => (
                                        !farm.token_ids.includes(BUSD_TOKEN_ID) && (
                                            <AshswapFarmPool
                                                key={index}
                                                commonSettings={ashswapCommonSetting}
                                                farm={farm}
                                                lendPools={lendPools}
                                                userTokens={userTokens}
                                                ashswapTokens={ashswapTokens}
                                                farmIndex={index}
                                                poolName='AshSwap'
                                            />
                                        )
                                    ))}
                                </div>
                            </div>
                        ) : (
                            <div className='mt-5'>
                                <Spinner />
                            </div>
                        )
                    )
                }

                {
                    dexType === 'onedex' && (
                        isOnedexFarmLoading ? (
                            <div className='mt-5'>
                                <Spinner />
                            </div>
                        ) : (
                            <div className='row mt-1' style={{ justifyContent: "center" }}>
                                {userTokens && onedexFarmCommonSettings && onedexFarms.length > 0 && (
                                    <div className='col-12'>
                                        {
                                            onedexFarms.map((farmPool: JewelOnedexFarmContext, index: number) => (
                                                <OneDexFarmPool
                                                    key={index}
                                                    farm={farmPool}
                                                    userTokens={userTokens}
                                                    poolName='OneDex'
                                                />
                                            ))
                                        }
                                    </div>
                                )}
                            </div>
                        )
                    )
                }

                {
                    dexType === 'hatom' && (
                        isHatomFarmLoading ? (
                            <div className='mt-5'>
                                <Spinner />
                            </div>
                        ) : (
                            <div className='row mt-1' style={{ justifyContent: "center" }}>
                                {userTokens && hatomFarmCommonSettings && hatomFarms.length > 0 && (
                                    <div className='col-12'>
                                        {
                                            hatomFarms.map((farmPool: JewelHatomFarmContext, index: number) => (
                                                getTokenTicker(farmPool.hatom_base_token_id) !== 'BUSD' && (
                                                    <HatomFarmPool
                                                        key={index}
                                                        farm={farmPool}
                                                        hatomMoneyMarkets={hatomMoneyMarkets}
                                                        hatomFormattedRewards={hatomFormattedRewards}
                                                        lendPools={lendPools}
                                                        userTokens={userTokens}
                                                        feePercent={hatomFarmCommonSettings.fee_percents[0] + hatomFarmCommonSettings.fee_percents[1]}
                                                        poolName='Hatom'
                                                        jewelHatomAprs={jewelHatomAprs}
                                                    />
                                                )
                                            ))
                                        }
                                    </div>
                                )}
                            </div>
                        )
                    )
                }

                {
                    dexType === 'jewelswap' && (
                        isJewelFlashMintLoading ? (
                            <div className='mt-5'>
                                <Spinner />
                            </div>
                        ) : (
                            <>
                                {!isMobile && (
                                    <div className='row mt-5' style={{ justifyContent: "center" }}>
                                        <div className='col-lg-3 col-md-3 col-sm-3 text-center'>
                                            <div>Pool</div>
                                        </div>
                                        <div className='col-lg-2 col-md-2 col-sm-2 text-center'>
                                            <div>TVL</div>
                                        </div>
                                        <div className='col-lg-2 col-md-2 col-sm-2 text-center'>
                                            <div>Total Borrowed</div>
                                        </div>
                                        <div className='col-lg-1 col-md-1 col-sm-1 d-flex justify-content-center'>
                                            <div className='d-flex'>
                                                <div>APY</div>
                                                <Tooltip
                                                    placement="top"
                                                    title="Based on APY of the current underlying farming pools. It does not include fees from occasional rebalancing"
                                                >
                                                    <InfoCircleOutlined className='h6 mx-1' style={{ marginTop: '1px', cursor: 'pointer' }} />
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div className='col-lg-2 col-md-2 col-sm-2 text-center'>
                                            <div>Leverage</div>
                                        </div>
                                        <div className='col-lg-2 col-md-2 col-sm-2'></div>
                                    </div>
                                )}
                                <div className='row mt-1' style={{ justifyContent: "center" }}>
                                    {userTokens && jewelFlashMintCommonSettings && jewelFlashMintFarms.length > 0 && (
                                        <div className='col-12'>
                                            {
                                                jewelFlashMintFarms.map((farmPool: JewelFlashMintFarmContext, index: number) => (
                                                    getTokenTicker(farmPool.farm_base_token_id) !== 'BUSD' && (
                                                        <JewelFarmPool
                                                            key={index}
                                                            commonSettings={jewelFlashMintCommonSettings}
                                                            farm={farmPool}
                                                            liquidStakeApy={liquidStakeApy}
                                                            hatomMoneyMarkets={hatomMoneyMarkets}
                                                            hatomFormattedRewards={hatomFormattedRewards}
                                                            userTokens={userTokens}
                                                            poolName='JewelSwap'
                                                        />
                                                    )
                                                ))
                                            }
                                        </div>
                                    )}
                                    {userTokens && jewelLpFarms.length > 0 && (
                                        <div className='col-12'>
                                            {
                                                jewelLpFarms.map((farmPool: JewelLpFarmContext, index: number) => (
                                                    <JewelLpFarmPool
                                                        key={index}
                                                        farm={farmPool}
                                                        jewelswapFarm={jewelswapFarms[index]}
                                                        ashswapTokens={ashswapTokens}
                                                        userTokens={userTokens}
                                                        poolName='JewelSwap'
                                                    />
                                                ))
                                            }
                                        </div>
                                    )}
                                </div>
                            </>
                        )
                    )
                }

                {
                    dexType === 'xexchange' && (
                        !exchangeFarmCommonSettings || isExchangeFarmLoading ? (
                            <div className='mt-5'>
                                <Spinner />
                            </div>
                        ) : (
                            <div className='col-12'>
                                {
                                    exchangeFarms.map((item: JewelExchangeFarmContext, index: number) => (
                                        <ExchangeFarmPool
                                          key={index}
                                          commonSetting={exchangeFarmCommonSettings}
                                          pool={item}
                                          userTokens={userTokens}
                                          poolName='xExchange'
                                          exchangeFarmInfo={exchangeFarmsInfo.find((value) => value.address === item.farm_address)}
                                        />
                                    ))
                                }
                            </div>
                        )
                    )
                }
            </div>
        </div>
    );
};
