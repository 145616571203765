import axios from "axios";
import {
  AbiRegistry,
  Address,
  SmartContract,
} from '@multiversx/sdk-core/out';
import { ApiNetworkProvider, ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';
import {
  NFT_LENDING_SC_ADDRESS,
  NFT_LENDING_SC_ABI_JSON,
  NFT_AMM_SC_ADDRESS,
  NFT_AMM_SC_ABI_JSON,
  JEWEL_LEND_V2_SC_ABI_JSON,
  JEWEL_LEND_V2_SC_ADDRESS,
  JEWEL_ASHSWAP_SC_ABI_JSON,
  JEWEL_ASHSWAP_SC_ADDRESS,
  ASHSWAP_FARM_SC_ABI_JSON,
  isDev,
  NETWORK_JEWEL_API,
  NETWORK_MULTIVERSX_API,
  API_TIMEOUT,
  JEWEL_STAKE_SC_ABI_JSON,
  JEWEL_STAKE_SC_ADDRESS,
  JEWEL_ONEDEX_FARM_ABI_JSON,
  JEWEL_ONEDEX_FARM_SC_ADDRESS,
  JEWEL_LIQUID_STAKE_SC_ADDRESS,
  JEWEL_LIQUID_STAKE_SC_ABI_JSON,
  NETWORK_PROXY_MULTIVERSX_API,
  JEWEL_GAUGE_SC_ABI_JSON,
  JEWEL_GAUGE_SC_ADDRESS,
  JEWEL_HATOM_FARM_SC_ABI_JSON,
  JEWEL_HATOM_FARM_SC_ADDRESS,
  JEWEL_HTM_STAKE_SC_ADDRESS,
  JEWEL_HTM_STAKE_SC_ABI_JSON,
  JEWEL_STABLE_SC_ABI_JSON,
  JEWEL_STABLE_SC_ADDRESS,
  JEWEL_MEX_STAKE_SC_ADDRESS,
  JEWEL_FLASH_MINT_SC_ABI_JSON,
  JEWEL_FLASH_MINT_SC_ADDRESS,
  JEWEL_BORROW_SC_ABI_JSON,
  JEWEL_BORROW_SC_ADDRESS,
  JEWEL_EXCHANGE_SC_ABI_JSON,
  JEWEL_EXCHANGE_SC_ADDRESS,
  JEWEL_UTK_STAKE_SC_ADDRESS,
  JEWEL_ITHEUM_STAKE_SC_ADDRESS,
  JEWEL_SWAP_ROUTER_SC_ABI_JSON,
  JEWEL_SWAP_ROUTER_SC_ADDRESS,
  JEWEL_FLEXILOAN_SC_ABI_JSON,
  JEWEL_FLEXILOAN_SC_ADDRESS,
  JEWEL_GLOBAL_POOL_SC_ABI_JSON,
  JEWEL_GLOBAL_POOL_SC_ADDRESS,
  JEWEL_ORACLE_SC_ABI_JSON,
  JEWEL_ORACLE_SC_ADDRESS,
  JEWEL_FACILITATOR_SC_ABI_JSON,
  JEWEL_FACILITATOR_SC_ADDRESS,
  JEWEL_BTC_ETH_STAKE_ABI_JSON,
  JEWEL_BTC_ETH_STAKE_SC_ADDRESS,
  HATOM_REWARDS_BOOSTER_SC_ABI_JSON,
  HATOM_REWARDS_BOOSTER_SC_ADDRESS,
  JEWEL_TADA_STAKE_SC_ADDRESS
} from 'config';
import { axiosConfig } from "z/api";

const abiRegistry = AbiRegistry.create(NFT_LENDING_SC_ABI_JSON);
// const abi = new SmartContractAbi(abiRegistry);
export const nftLendingSmartContract = new SmartContract({
  address: new Address(NFT_LENDING_SC_ADDRESS),
  abi: abiRegistry,
});

const ammAbiRegistry = AbiRegistry.create(NFT_AMM_SC_ABI_JSON);
// const ammAbi = new SmartContractAbi(ammAbiRegistry);
export const nftAmmSmartContract = new SmartContract({
  address: new Address(NFT_AMM_SC_ADDRESS),
  abi: ammAbiRegistry,
});

const jewelLendAbiRegistry = AbiRegistry.create(JEWEL_LEND_V2_SC_ABI_JSON);
// const jewelLendAbi = new SmartContractAbi(jewelLendAbiRegistry);
export const jewelLendSmartContract = new SmartContract({
  address: new Address(JEWEL_LEND_V2_SC_ADDRESS),
  abi: jewelLendAbiRegistry,
});

const jewelFarmAbiRegistry = AbiRegistry.create(JEWEL_ASHSWAP_SC_ABI_JSON);
// const jewelFarmAbi = new SmartContractAbi(jewelFarmAbiRegistry);
export const jewelUsdtAshFarmSmartContract = new SmartContract({
  address: new Address(JEWEL_ASHSWAP_SC_ADDRESS),
  abi: jewelFarmAbiRegistry,
});


export const farmAbiRegistry = AbiRegistry.create(ASHSWAP_FARM_SC_ABI_JSON);

// jewel stake
const jewelStakeAbiRegistry = AbiRegistry.create(JEWEL_STAKE_SC_ABI_JSON);
// const jewelStakeAbi = new SmartContractAbi(jewelStakeAbiRegistry);
export const jewelStakeSmartContract = new SmartContract({
  address: new Address(JEWEL_STAKE_SC_ADDRESS),
  abi: jewelStakeAbiRegistry,
});

//htm stake

const jewelHtmStakeAbiRegistry = AbiRegistry.create(JEWEL_HTM_STAKE_SC_ABI_JSON);
// const jewelHtmStakeAbi = new SmartContractAbi(jewelHtmStakeAbiRegistry);
export const jewelHtmStakeSmartContract = new SmartContract({
  address: new Address(JEWEL_HTM_STAKE_SC_ADDRESS),
  abi: jewelHtmStakeAbiRegistry,
});
// itheum stake
const jewelItheumStakeAbiRegistry = AbiRegistry.create(JEWEL_STAKE_SC_ABI_JSON);
// const jewelHtmStakeAbi = new SmartContractAbi(jewelHtmStakeAbiRegistry);
export const jewelItheumStakeSmartContract = new SmartContract({
  address: new Address(JEWEL_ITHEUM_STAKE_SC_ADDRESS),
  abi: jewelItheumStakeAbiRegistry,
});

// tada stake
const jewelTadaStakeAbiRegistry = AbiRegistry.create(JEWEL_STAKE_SC_ABI_JSON);
// const jewelHtmStakeAbi = new SmartContractAbi(jewelHtmStakeAbiRegistry);
export const jewelTadaStakeSmartContract = new SmartContract({
  address: new Address(JEWEL_TADA_STAKE_SC_ADDRESS),
  abi: jewelTadaStakeAbiRegistry,
});

// mex stake
const jewelMexStakeAbiRegistry = AbiRegistry.create(JEWEL_STAKE_SC_ABI_JSON);
// const jewelHtmStakeAbi = new SmartContractAbi(jewelHtmStakeAbiRegistry);
export const jewelMexStakeSmartContract = new SmartContract({
  address: new Address(JEWEL_MEX_STAKE_SC_ADDRESS),
  abi: jewelMexStakeAbiRegistry,
});

// utk stake
const jewelUtkStakeAbiRegistry = AbiRegistry.create(JEWEL_STAKE_SC_ABI_JSON);
// const jewelHtmStakeAbi = new SmartContractAbi(jewelHtmStakeAbiRegistry);
export const jewelUtkStakeSmartContract = new SmartContract({
  address: new Address(JEWEL_UTK_STAKE_SC_ADDRESS),
  abi: jewelUtkStakeAbiRegistry,
});

// onedex farm
const jewelOnedexFarmAbiRegistry = AbiRegistry.create(JEWEL_ONEDEX_FARM_ABI_JSON);
// const jewelOnedexFarmAbi = new SmartContractAbi(jewelOnedexFarmAbiRegistry);
export const jewelOnedexFarmSmartContract = new SmartContract({
  address: new Address(JEWEL_ONEDEX_FARM_SC_ADDRESS),
  abi: jewelOnedexFarmAbiRegistry,
});

// liquid stake
export const jewelLiquidStakeAbiRegistry = AbiRegistry.create(JEWEL_LIQUID_STAKE_SC_ABI_JSON);
// const jewelLiquidStakeAbi = new SmartContractAbi(jewelLiquidStakeAbiRegistry);
export const jewelLiquidStakeSmartContract = new SmartContract({
  address: new Address(JEWEL_LIQUID_STAKE_SC_ADDRESS),
  abi: jewelLiquidStakeAbiRegistry,
});

// Gauge
const jewelGaugeAbiRegistry = AbiRegistry.create(JEWEL_GAUGE_SC_ABI_JSON);
// const jewelGaugeAbi = new SmartContractAbi(jewelGaugeAbiRegistry);
export const jewelGaugeSmartContract = new SmartContract({
  address: new Address(JEWEL_GAUGE_SC_ADDRESS),
  abi: jewelGaugeAbiRegistry,
});

// Jewel Swap Router
const jewelSwapRouterAbiRegistry = AbiRegistry.create(JEWEL_SWAP_ROUTER_SC_ABI_JSON);
export const jewelSwapRouterSmartContract = new SmartContract({
  address: new Address(JEWEL_SWAP_ROUTER_SC_ADDRESS),
  abi: jewelSwapRouterAbiRegistry,
});

// Hatom Farm
const jewelHatomFarmAbiRegistry = AbiRegistry.create(JEWEL_HATOM_FARM_SC_ABI_JSON);
// const jewelHatomFarmAbi = new SmartContractAbi(jewelHatomFarmAbiRegistry);
export const jewelHatomFarmSmartContract = new SmartContract({
  address: new Address(JEWEL_HATOM_FARM_SC_ADDRESS),
  abi: jewelHatomFarmAbiRegistry,
});

// JWLUSD
const jewelStableAbiRegistry = AbiRegistry.create(JEWEL_STABLE_SC_ABI_JSON);
// const jewelStableAbi = new SmartContractAbi(jewelStableAbiRegistry);
export const jewelStableSmartContract = new SmartContract({
  address: new Address(JEWEL_STABLE_SC_ADDRESS),
  abi: jewelStableAbiRegistry
});

// Jewel Flash Mint
const jewelFlashMintAbiRegistry = AbiRegistry.create(JEWEL_FLASH_MINT_SC_ABI_JSON);
export const jewelFlashMintSmartContract = new SmartContract({
  address: new Address(JEWEL_FLASH_MINT_SC_ADDRESS),
  abi: jewelFlashMintAbiRegistry
});

// Jewel Borrow
const jewelBorrowAbiRegistry = AbiRegistry.create(JEWEL_BORROW_SC_ABI_JSON);
export const jewelBorrowSmartContract = new SmartContract({
  address: new Address(JEWEL_BORROW_SC_ADDRESS),
  abi: jewelBorrowAbiRegistry,
});

// Jewel xExchange Farm
const jewelExchangeAbiRegistry = AbiRegistry.create(JEWEL_EXCHANGE_SC_ABI_JSON);
export const jewelExchangeSmartContract = new SmartContract({
  address: new Address(JEWEL_EXCHANGE_SC_ADDRESS),
  abi: jewelExchangeAbiRegistry,
});

// Jewel Flexiloan
const jewelFlexiLoanAbiRegistry = AbiRegistry.create(JEWEL_FLEXILOAN_SC_ABI_JSON);
export const jewelFlexiLoanSmartContract = new SmartContract({
  address: new Address(JEWEL_FLEXILOAN_SC_ADDRESS),
  abi: jewelFlexiLoanAbiRegistry,
});

// Jewel Global Pool
const jewelGlobalPoolAbiRegistry = AbiRegistry.create(JEWEL_GLOBAL_POOL_SC_ABI_JSON);
export const jewelGlobalPoolSmartContract = new SmartContract({
  address: new Address(JEWEL_GLOBAL_POOL_SC_ADDRESS),
  abi: jewelGlobalPoolAbiRegistry,
});

const jewelOracleAbiRegistry = AbiRegistry.create(JEWEL_ORACLE_SC_ABI_JSON);
export const jewelOracleSmartContract = new SmartContract({
  address: new Address(JEWEL_ORACLE_SC_ADDRESS),
  abi: jewelOracleAbiRegistry,
});

// Jewel Facilitator
const jewelFacilitatorAbiRegistry = AbiRegistry.create(JEWEL_FACILITATOR_SC_ABI_JSON);
export const jewelFacilitatorSmartContract = new SmartContract({
  address: new Address(JEWEL_FACILITATOR_SC_ADDRESS),
  abi: jewelFacilitatorAbiRegistry,
});

// Jewel BTC, ETH Stake
const jewelBtcEthStakeAbiRegistry = AbiRegistry.create(JEWEL_BTC_ETH_STAKE_ABI_JSON);
export const jewelBtcEthStakeSmartContract = new SmartContract({
  address: new Address(JEWEL_BTC_ETH_STAKE_SC_ADDRESS),
  abi: jewelBtcEthStakeAbiRegistry,
});

// Hatom Rewards Booster
const hatomRewardsBoosterAbiRegistry = AbiRegistry.create(HATOM_REWARDS_BOOSTER_SC_ABI_JSON);
export const hatomRewardsBoosterSmartContract = new SmartContract({
  address: new Address(HATOM_REWARDS_BOOSTER_SC_ADDRESS),
  abi: hatomRewardsBoosterAbiRegistry,
});

export const getNetworkProvider = async () => {
  // const networkProvider = new ProxyNetworkProvider(NETWORK_PROXY_MULTIVERSX_API, { timeout: API_TIMEOUT });
  // return networkProvider;
  const networkProvider = new ApiNetworkProvider(NETWORK_MULTIVERSX_API, { timeout: API_TIMEOUT });
  if (isDev) {
    return networkProvider;
  } else {
    const url = NETWORK_JEWEL_API + '/about';
    try {
      const data = await axios.get(url);

      if (data.status === 200) {
        const jewelProxyNetworkProvider = new ProxyNetworkProvider(NETWORK_JEWEL_API, { timeout: API_TIMEOUT });
        return jewelProxyNetworkProvider;
      } else {
        return networkProvider;
      }
    } catch (error) {
      console.log(error);
      return networkProvider;
    }


  }
};

export const getMvxProvider = async () => {
  const networkProvider = new ApiNetworkProvider(NETWORK_MULTIVERSX_API, { timeout: API_TIMEOUT });
  return networkProvider;
};

export const apiNetworkProvider = new ApiNetworkProvider(NETWORK_MULTIVERSX_API, { timeout: API_TIMEOUT });
