import { TransactionTypesEnum } from "@multiversx/sdk-dapp/types";
import { CollectionType, NftEnumType, NftType, TokenType } from "@multiversx/sdk-dapp/types/tokens.types";
import axios from "axios";
import { JEWEL_LIQUID_STAKE_SC_ADDRESS, NETWORK_MULTIVERSX_API } from "config";
import { NftWithIndexType, CollectionWithIndexType, NftCollection, NftWithAmmData, PoolNft, Delegation, ElrondStatsType } from "z/types";
import { ERROR_MVX_API_FAILED, toastError, createNftId, NFT_QUANTITY, convertSecondsToPrintDateTime } from "z/utils";
import { axiosConfig } from ".";

export async function getNftsByCollectionFromApi(apiAddress: string, address: string, collection_id: string): Promise<NftWithIndexType[]> {
    
  //const configUrl = `${apiAddress}/nfts?size=200&collections=${collection_id}`;
  const configUrl = `${apiAddress}/accounts/${address}/nfts?size=200&collections=${collection_id}`;

  try {
    const { data } = await axios.get<NftType[]>(configUrl, axiosConfig);

    const items: NftWithIndexType[] = [];
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        items.push({
          ...data[i],
          index: i,
          ticker: data[i].ticker
        });
      }
    }

    return items;
  } catch (e) {
    console.log('getNftsByCollectionFromApi: ', e);
    // toastError(ERROR_MVX_API_FAILED);
  }
  return [];
}

export async function getNftsByCollectionsFromApi(apiAddress: string, address: string, collection_ids: string[]): Promise<NftWithIndexType[]> {
  const configUrl = `${apiAddress}/accounts/${address}/nfts?size=10000&collections=${collection_ids.join(',')}`;

  try {
    const { data } = await axios.get<NftType[]>(configUrl, axiosConfig);

    const items: NftWithIndexType[] = [];
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        items.push({
          ...data[i],
          index: i,
        });
      }
    }

    return items;
  } catch (e) {
    console.log('getNftsByCollectionFromApi: ', e);
    // toastError(ERROR_MVX_API_FAILED);
  }
  return [];
}

export async function getNftsByIdFromApi(apiAddress: string, nftIds: string[]): Promise<NftWithIndexType[]> {
  if (nftIds.length === 0) return [];

  const configUrl = `${apiAddress}/nfts?size=10000&identifiers=${nftIds.join(',')}`;

  try {
    const { data } = await axios.get<NftType[]>(configUrl, axiosConfig);

    const items: NftWithIndexType[] = [];
    if (data.length > 0) {
      for (let i = 0; i < nftIds.length; i++) {
        let foundMatch = false;
        for (let j = 0; j < data.length; j++) {
          if (nftIds[i] == data[j].identifier) {
            items.push({
              ...data[j],
              index: i,
            });
            foundMatch = true;
            break;
          }
        }

        // if matching NFT is not retrieved from API, push a dummy item for count
        if (!foundMatch) {
          items.push({
            index: i,
            identifier: nftIds[i],
            collection: '',
            timestamp: 0,
            attributes: '',
            nonce: 0,
            type: NftEnumType.NonFungibleESDT,
            name: '',
            creator: '',
            royalties: 0,
            balance: '0',
          });
        }
      }
    }

    // error
    if (nftIds.length !== items.length) {
      console.error('getNftsByIdFromApi: number of input and output items do not match');
    }

    return items;
  } catch (e) {
    // toastError(ERROR_MVX_API_FAILED);
  }
  return [];
}

// export async function getAmmCollectionsFromApi(apiAddress: string, collections: NftCollection[]): Promise<CollectionWithIndexType[]> {
//   let collection = '';
//   collections.map((item: NftCollection, index: number) => {
//     collection += item.nft_token_id;
//     if ((index + 1) < collections.length) {
//       collection += ',';
//     }
//   });
//   const configUrl = `${apiAddress}/collections?size=${collections.length}&identifiers=${collection}`;

//   try {
//     const { data } = await axios.get<CollectionType[]>(configUrl, axiosConfig);

//     const items: CollectionWithIndexType[] = [];
//     if (data.length > 0) {
//       for (let i = 0; i < data.length; i++) {
//         items.push({
//           ...data[i],
//           index: i,
//         });
//       }
//     }

//     return items;
//   } catch (e) {
//     toastError(ERROR_MVX_API_FAILED);
//   }
//   return [];
// }

export async function getAmmNftsByCollectionFromApi(apiAddress: string, address: string, collectionId: string, isPool: boolean, poolNfts?: PoolNft[]): Promise<NftWithAmmData[]> {
  const configUrl = `${apiAddress}/accounts/${address}/nfts?size=10000&collections=${collectionId}`;

  try {
    const { data } = await axios.get<NftType[]>(configUrl, axiosConfig);

    const items: NftWithAmmData[] = [];
    if (data.length > 0) {
      if (isPool && poolNfts) {
        // pool nfts
        for (let i = 0; i < poolNfts.length; i++) {
          for (let j = 0; j < data.length; j++) {
            if (poolNfts[i].nft_token_nonce === data[j].nonce) {
              items.push({
                ...data[j],
                ...poolNfts[i],
              });
              break;
            }
          }
        }
      } else {
        // user nfts
        for (let i = 0; i < data.length; i++) {
          let balance = NFT_QUANTITY.toString();
          if (data[i].type == NftEnumType.SemiFungibleESDT) {
            balance = data[i].balance;
          }
          items.push({
            ...data[i],
            nft_token_amount: balance,
            pool_id: 0,
          });
        }
      }
    }

    return items;
  } catch (e) {
    console.log('getAmmNftsByCollectionFromApi: ', e);
    // toastError(ERROR_MVX_API_FAILED);
  }
  return [];
}

// export async function getAmmNftsByCollectionFromApi(apiAddress: string, address: string, collectionId: string, isPool: boolean, poolNfts?: PoolNft[]): Promise<NftWithAmmData[]> {
//   const configUrl = `${apiAddress}/accounts/${address}/nfts?size=10000&collections=${collectionId}`;

//   try {
//     const items: NftWithAmmData[] = [];
//     if (isPool && poolNfts) {
//       // pool nfts
//       for (let i = 0; i < poolNfts.length; i++) {
//         let nftInfo: NftInfoType = {
//           nonce: 0,
//           name: '',
//           url: '',
//         };
//         if (COLLECTIONS_MAP[poolNfts[i].nft_token_id]) {
//           const collectionInfo: CollectionSimpleType = COLLECTIONS_MAP[poolNfts[i].nft_token_id];
//           if (collectionInfo.nft) {
//             nftInfo = collectionInfo.nft.filter((item: NftInfoType) => item.nonce === poolNfts[i].nft_token_nonce)[0];
//           }
//         }

//         if (poolNfts[i].nft_token_nonce === nftInfo.nonce) {
//           items.push({
//             ...poolNfts[i],
//             ...nftInfo,
//             identifier: createNftId(poolNfts[i].nft_token_id, poolNfts[i].nft_token_nonce),
//             collection: poolNfts[i].nft_token_id,
//             timestamp: 0,
//             attributes: '',
//             type: COLLECTIONS_MAP[poolNfts[i].nft_token_id].type,
//             creator: '',
//             royalties: 0,
//             balance: '0',
//           });
//         }
//       }
//     } else {
//       // user nfts
//       const { data } = await axios.get<NftType[]>(configUrl, axiosConfig);

//       if (data.length > 0) {
//         for (let i = 0; i < data.length; i++) {
//           let balance = NFT_QUANTITY.toString();
//           if (data[i].type == NftEnumType.SemiFungibleESDT) {
//             balance = data[i].balance;
//           }
//           items.push({
//             ...data[i],
//             nft_token_amount: balance,
//             pool_id: 0,
//           });
//         }
//       }
//     }

//     return items;
//   } catch (e) {
//     toastError(ERROR_MVX_API_FAILED);
//   }
//   return [];
// }

export async function getTransactions(apiAddress: string, address: string, size: number) {
  const configUrl = `${apiAddress}/accounts/${address}/transactions?size=${size}&status=success&function=swapNftForEgld%2CswapEgldForNft&order=desc`;
  try {
    const { data } = await axios.get(configUrl, axiosConfig);

    const items = [];
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        items.push({
          ...data[i],
          index: i,
        });
      }
    }

    return items;
  } catch (e) {
    console.log('getTransactions: ', e);
    // toastError(ERROR_MVX_API_FAILED);
  }
  return [];
}

export async function getUserTokenBalance(apiAddress: string, address: string, tokenIds: string[]) {
  const configUrl = `${apiAddress}/accounts/${address}/tokens?size=200&identifiers=${tokenIds}`;
  try {
    const { data } = await axios.get<TokenType[]>(configUrl, axiosConfig);
    return data;
  } catch (e) {
    console.log('getUserTokenBalance: ', e);
    // toastError(ERROR_MVX_API_FAILED);
  }
  return [];
}

export async function getAccountDelegation(apiAddress: string): Promise<Delegation[]> {
  const url = `${apiAddress}/accounts/${JEWEL_LIQUID_STAKE_SC_ADDRESS}/delegation`;
  const { data } = await axios.get<Delegation[]>(url);
  return data;
}

interface MexTokenDetail{
  id: string,
  symbol: string,
  name: string,
  price: number,
}

export async function getXexchangeTokenPrice(apiAddress: string, tokenId: string): Promise<number> {
  const configUrl = `${apiAddress}/mex/tokens/${tokenId}`;
  try {
    const { data } = await axios.get<MexTokenDetail>(configUrl, axiosConfig);

    if (data) {
      return data.price;
    } else {
      return 0;
    }
  } catch (e) {
    console.log('getXexchangeTokenPrice: ', e);
    // toastError(ERROR_MVX_API_FAILED);
  }
  return 0;
}

export async function getXexchangeTokenPrices(apiAddress: string): Promise<MexTokenDetail[]> {
  const configUrl = `${apiAddress}/mex/tokens?from=0&size=1000`;
  try {
    const { data } = await axios.get<MexTokenDetail[]>(configUrl, axiosConfig);

    if (data) {
      return data;
    } else {
      return [];
    }
  } catch (e) {
    console.log('getXexchangeTokenPrice: ', e);
    // toastError(ERROR_MVX_API_FAILED);
  }
  return [];
}


export async function getElrondStatsFromApi(): Promise<ElrondStatsType | undefined> {
  const configUrl = `${NETWORK_MULTIVERSX_API}/stats`;
  
  try {
      const { data } = await axios.get<ElrondStatsType>(configUrl, axiosConfig);
      
      if (data) {
          data.leftTime = convertSecondsToPrintDateTime((data.roundsPerEpoch - data.roundsPassed) * 6);
          data.passedTimePercentage = (data.roundsPassed / data.roundsPerEpoch) * 100;
      }
      
      return data;
  } catch (err) {
      console.error('getElrondStatsFromApi failed:', err);
      // toastError(ERROR_MVX_API_FAILED);
  }

  return undefined;
}

export async function getStakingProviderApr(apiAddress: string, providerScAddress: string): Promise<number> {
  const configUrl = `${apiAddress}/providers/${providerScAddress}`;
  try {
    const { data } = await axios.get(configUrl, axiosConfig);

    if (data) {
      return data.apr;
    } else {
      return 0;
    }
  } catch (e) {
    console.log('getStakingProviderApr: ', e);
    // toastError(ERROR_MVX_API_FAILED);
  }
  return 0;
}

export async function getAccountBalance(apiAddress: string, address: string): Promise<string> {
  const configUrl = `${apiAddress}/accounts/${address}?withGuardianInfo=false&fields=balance`;

  try {
    const { data } = await axios.get(configUrl, axiosConfig);

    return data.balance;
  } catch (e) {
    console.log('getAccountBalance: ', e);
    // toastError(ERROR_MVX_API_FAILED);
  }
  return '0';
}
