import { AbiRegistry, Address, Interaction, ResultsParser, SmartContract, TokenTransfer, Transaction } from "@multiversx/sdk-core/out";
import { ProxyNetworkProvider } from '@multiversx/sdk-network-providers/out';
import BigNumber from 'bignumber.js';
import { API_TIMEOUT, NETWORK_JEWEL_API, isDev } from 'config';
import { MAX_GAS_LIMIT } from "./constants";
import { mvxNetworkConfigs } from "./network-config";
import { sendTransactions } from "@multiversx/sdk-dapp/services";
import { getNetworkProvider } from "./elrond-provider";

export const mvxNetworkConfig = mvxNetworkConfigs[isDev ? 'devnet' : 'mainnet'];

export const proxyNetworkProvider = new ProxyNetworkProvider(isDev ? mvxNetworkConfig.gatewayAddress : NETWORK_JEWEL_API, { timeout: API_TIMEOUT });

export function createSmartContract(abiJson: any, scAddress?: string): SmartContract {
  const abiRegistry = AbiRegistry.create(abiJson);
  return new SmartContract({
      address: scAddress ? new Address(scAddress) : undefined,
      abi: abiRegistry,
  });
}
export async function mvxQuery(interaction: Interaction) {
  const query = interaction.check().buildQuery();
  const queryResponse = await (await getNetworkProvider()).queryContract(query);
  const endpointDefinition = interaction.getEndpoint();
  const { firstValue, returnCode, returnMessage } = new ResultsParser()
    .parseQueryResponse(
      queryResponse,
      endpointDefinition,
    );
  
  if (!returnCode.isSuccess()) {
    throw Error(`mvxQuery: ${returnCode}: ${returnMessage}`);
  }
  if (!firstValue) {
    throw Error('mvxQuery: firstValue null');
  }
  
  const value = firstValue.valueOf();
  return value;
}

export function mvxCreateTransaction({
  sender,
  nonce,
  interaction,
  payments,
  value,
  gasLimit,
} : {
  sender: string,
  nonce: number,
  interaction: Interaction,
  payments?: TokenTransfer[],
  value?: BigNumber.Value,
  gasLimit?: number,
}): Transaction {
  if (payments) {
    if (payments.length == 0) {
      throw new Error('mvxSendTransaction: payments.length zero');
    }
    if (payments.length == 1 && payments[0].nonce == 0) {   // Single ESDT Transfer
      interaction = interaction.withSingleESDTTransfer(payments[0]);
    } else {    // Multi ESDT or NFT Transfer
      interaction = interaction.withMultiESDTNFTTransfer(payments);
    } 
  }
  if (value) {
    interaction = interaction.withValue(value);
  }
  if (gasLimit) {
    interaction = interaction.withGasLimit(gasLimit);
  } else {
    interaction = interaction.withGasLimit(MAX_GAS_LIMIT);
  }
  interaction = interaction
    .withSender(new Address(sender))
    .withNonce(nonce)
    .withChainID(mvxNetworkConfig.chainId);

  const tx = interaction.check().buildTransaction();
  return tx;
}

export const COMMON_GAS_LIMIT = 300_000_000;
export async function mvxSendTransaction({
  interaction,
  payments,
  value,
  gasLimit,
  txName,
  address
} : {
  interaction: Interaction,
  payments?: TokenTransfer[],
  value?: BigNumber.Value,
  gasLimit?: number,
  txName: string,
  address: string
}) {
  try {
      if (payments) {
          if (payments.length == 0) {
              throw new Error('payments.length zero');
          }
          if (payments.length == 1 && payments[0].nonce == 0) {   // Single ESDT Transfer
              interaction = interaction.withSingleESDTTransfer(payments[0]);
          } else {    // Multi ESDT or NFT Transfer
              interaction = interaction.withMultiESDTNFTTransfer(payments);
          } 
      }
      if (value) {
          interaction = interaction.withValue(value);
      }
      if (gasLimit) {
          interaction = interaction.withGasLimit(gasLimit);
      } else {
          interaction = interaction.withGasLimit(COMMON_GAS_LIMIT);
      }
      interaction = interaction.withChainID(isDev ? 'D' : '1');
      interaction = interaction.withSender(new Address(address));
      const tx = interaction.check().buildTransaction();

  
      const { sessionId, error } = await sendTransactions({
          transactions: [tx],
          transactionsDisplayInfo: {
              processingMessage: `Processing ${txName} Request`,
              errorMessage: `Error occured during ${txName} Request`,
              successMessage: `${txName} Request Successful`,
          },
          redirectAfterSign: false,
      });
  
      return { sessionId, error };
  } catch (err) {
      console.error(err);
  }
}